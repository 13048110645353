export const photos = [
  {
    id: 1,
    country: 'italy',
    location: 'florence',
    name: 'Florence'
  },
  {
    id: 2,
    country: 'italy',
    location: 'florence',
    name: 'Florence'
  },
  {
    id: 3,
    country: 'italy',
    location: 'florence',
    name: 'Florence'
  },
  {
    id: 4,
    country: 'italy',
    location: 'florence',
    name: 'Florence'
  }

]