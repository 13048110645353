export const locations = [
  {
    id: 1,
    country: 'france',
    name: 'Bethune',
    thumb: 'bethune'
  },
  {
    id: 2,
    country: 'france',
    name: 'Dijon',
    thumb: 'dijon'
  },
  {
    id: 3,
    country: 'france',
    name: 'Marseille',
    thumb: 'marseille'
  }

]