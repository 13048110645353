export const photos = [
  {
    id: 1,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Barbuda Express ferry'
  },
  {
    id: 2,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Barbuda Express ferry'
  },
  {
    id: 3,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Frigate bird sanctuary'
  },
  {
    id: 4,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Frigate bird sanctuary'
  },
  {
    id: 5,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Frigate bird sanctuary'
  },
  {
    id: 6,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Frigate bird sanctuary'
  },
  {
    id: 7,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Frigate bird sanctuary'
  },
  {
    id: 8,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Pink sand beach'
  },
  {
    id: 9,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Pink sand beach'
  },
  {
    id: 10,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Pink sand beach'
  },
  {
    id: 11,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Pink sand beach'
  },
  {
    id: 12,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Pink sand beach'
  },
  {
    id: 13,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Pink sand beach'
  },
  {
    id: 14,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Pink sand beach'
  },
  {
    id: 15,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Two foot bay cave'
  },
  {
    id: 16,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Two foot bay cave'
  },
  {
    id: 17,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Two foot bay cave'
  },
  {
    id: 18,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Two foot bay cave'
  },
  {
    id: 19,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Two foot bay cave'
  },
  {
    id: 20,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Two foot bay cave'
  },
  {
    id: 21,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Two foot bay cave'
  },
  {
    id: 22,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Princess Diana beach'
  },
  {
    id: 23,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Princess Diana beach'
  },
  {
    id: 24,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Princess Diana beach'
  },
  {
    id: 25,
    country: 'antigua-and-barbuda',
    location: 'barbuda',
    name: 'Princess Diana beach'
  }
]