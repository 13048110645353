export const locations = [
  {
    id: 1,
    country: 'italy',
    name: 'Capri',
    thumb: 'capri'
  },
  {
    id: 2,
    country: 'italy',
    name: 'Florence',
    thumb: 'florence'
  },
  {
    id: 3,
    country: 'italy',
    name: 'Lake Como',
    thumb: 'como'
  },
  {
    id: 4,
    country: 'italy',
    name: 'Monza',
    thumb: 'monza'
  },
  {
    id: 5,
    country: 'italy',
    name: 'Pisa',
    thumb: 'pisa'
  },
  {
    id: 6,
    country: 'italy',
    name: 'Rome',
    thumb: 'rome'
  },
  {
    id: 7,
    country: 'italy',
    name: 'Sorrento',
    thumb: 'sorrento'
  },
  {
    id: 8,
    country: 'italy',
    name: 'Trieste',
    thumb: 'trieste'
  },
  {
    id: 9,
    country: 'italy',
    name: 'Turin',
    thumb: 'turin'
  }

]