export const locations = [
  {
    id: 1,
    country: 'canada',
    name: 'Banff',
    thumb: 'banff'
  },
  {
    id: 2,
    country: 'canada',
    name: 'Bowen Island',
    thumb: 'bowen'
  },
  {
    id: 3,
    country: 'canada',
    name: 'Drumheller',
    thumb: 'drumheller'
  },
  {
    id: 4,
    country: 'canada',
    name: 'Duffey Lake',
    thumb: 'duffey'
  },
  {
    id: 5,
    country: 'canada',
    name: 'Icefields Parkway',
    thumb: 'icefields'
  },
  {
    id: 6,
    country: 'canada',
    name: 'Jasper',
    thumb: 'jasper'
  },
  {
    id: 7,
    country: 'canada',
    name: 'Joffre Lakes',
    thumb: 'joffre'
  },
  {
    id: 8,
    country: 'canada',
    name: 'Lake Louise',
    thumb: 'louise'
  },
  {
    id: 9,
    country: 'canada',
    name: 'Lake Moraine',
    thumb: 'moraine'
  },
  {
    id: 10,
    country: 'canada',
    name: 'Ottawa',
    thumb: 'ottawa'
  },
  {
    id: 11,
    country: 'canada',
    name: 'Squamish',
    thumb: 'squamish'
  },
  {
    id: 12,
    country: 'canada',
    name: 'Yellowhead',
    thumb: 'yellowhead'
  },
]