export const photos = [
  {
    id: 1,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 2,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 3,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 4,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 5,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 6,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 7,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 8,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 9,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 10,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 11,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 12,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 13,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 14,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 15,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 16,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 17,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 18,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 19,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 20,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  },
  {
    id: 21,
    country: 'england',
    location: 'buxton',
    name: 'Buxton'
  }
 
]