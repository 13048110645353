export const photos = [
  {
    id: 1,
    country: 'ireland',
    location: 'dublin',
    name: 'Dublin'
  },
  {
    id: 2,
    country: 'ireland',
    location: 'dublin',
    name: 'Dublin'
  },
  {
    id: 3,
    country: 'ireland',
    location: 'dublin',
    name: 'Dublin'
  },
  {
    id: 4,
    country: 'ireland',
    location: 'dublin',
    name: 'Dublin'
  },
  {
    id: 5,
    country: 'ireland',
    location: 'dublin',
    name: 'Dublin'
  },
  {
    id: 6,
    country: 'ireland',
    location: 'dublin',
    name: 'Dublin'
  }

]