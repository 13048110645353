export const employment = [
  {
    id: 1,
    name: 'Eurostar',
    logo: 'eurostar.png',
    position: 'QA Engineer',
    from: '2006',
    to: '2017'
  },
  {
    id: 2,
    name: 'Rail Delivery Group',
    logo: 'rdg.png',
    position: 'Test Lead/Analyst',
    from: '2017',
    to: '2018'
  },
  {
    id: 3,
    name: 'Cloud IQ',
    logo: 'ciq.png',
    position: 'QA Engineer',
    from: '2018',
    to: '2020'
  },
  {
    id: 3,
    name: 'Bulb',
    logo: 'bulb.jpg',
    position: 'QA',
    from: '2020',
    to: 'Present'
  }

]