export const photos = [
  {
    id: 1,
    country: 'italy',
    location: 'pisa',
    name: 'Pisa'
  },
  {
    id: 2,
    country: 'italy',
    location: 'pisa',
    name: 'Pisa'
  },
  {
    id: 3,
    country: 'italy',
    location: 'pisa',
    name: 'Pisa'
  },
  {
    id: 4,
    country: 'italy',
    location: 'pisa',
    name: 'Pisa'
  }

]