export const photos = [
  {
    id: 1,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 2,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 3,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 4,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 5,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 6,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 7,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 8,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 9,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 10,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 11,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 12,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 13,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 14,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 15,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 16,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 17,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 18,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 19,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 20,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 21,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 22,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 23,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 24,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 25,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 26,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 27,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 28,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 29,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 30,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 31,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 32,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 33,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 34,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 35,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 36,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 37,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 38,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 39,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 40,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 41,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 42,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 43,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 44,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 45,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 46,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 47,
    country: 'canada',
    location: 'ottawa',
    name: 'Pink Lake'
  },
  {
    id: 48,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 49,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 50,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 51,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 52,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 53,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 54,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 55,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 56,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 57,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 58,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 59,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 60,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 61,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },
  {
    id: 62,
    country: 'canada',
    location: 'ottawa',
    name: 'Ottawa'
  },

]