export const photos = [
  {
    id: 1,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Fort James'
  },
  {
    id: 2,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Fort James'
  },
  {
    id: 3,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Fort James'
  },
  {
    id: 4,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Fort Barrington'
  },
  {
    id: 5,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Fort Barrington'
  },
  {
    id: 6,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Shirley Heights Lookout'
  },
  {
    id: 7,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Nelsons Dockyard, English Harbour'
  },
  {
    id: 8,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Nelsons Dockyard, English Harbour'
  },
  {
    id: 9,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Nelsons Dockyard, English Harbour'
  },
  {
    id: 10,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Nelsons Dockyard, English Harbour'
  },
  {
    id: 11,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Nelsons Dockyard, English Harbour'
  },
  {
    id: 12,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Nelsons Dockyard, English Harbour'
  },
  {
    id: 13,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Nelsons Dockyard, English Harbour'
  },
  {
    id: 14,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Bettys Hope, Sugar Plantation'
  },
  {
    id: 15,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Bettys Hope, Sugar Plantation'
  },
  {
    id: 16,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Bettys Hope, Sugar Plantation'
  },
  {
    id: 17,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Bettys Hope, Sugar Plantation'
  },
  {
    id: 18,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Bettys Hope, Sugar Plantation'
  },
  {
    id: 19,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: 'Bettys Hope, Sugar Plantation'
  },
  {
    id: 20,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: "Devil's bridge"
  },
  {
    id: 21,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: "Devil's bridge"
  },
  {
    id: 22,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: "Devil's bridge"
  },
  {
    id: 23,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: "Devil's bridge"
  },
  {
    id: 24,
    country: 'antigua-and-barbuda',
    location: 'antigua-historic',
    name: "Devil's bridge"
  }
]