import React from 'react';
import '../styles/styles.css';

const Footer = () => {
  return (
    <div data-test='footer' className='footer'>
      <p>© maxharding4</p>
    </div>

  )
};

export default Footer;