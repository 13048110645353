export const photos = [
  {
    id: 1,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 2,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 3,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 4,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 5,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 6,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 7,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 8,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 9,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 10,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 11,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 12,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 13,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 14,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 15,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 16,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 17,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 18,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  },
  {
    id: 19,
    country: 'spain',
    location: 'valencia',
    name: 'Valencia'
  }

]