export const photos = [
  {
    id: 1,
    country: 'canada',
    location: 'duffey',
    name: 'Duffey'
  },
  {
    id: 2,
    country: 'canada',
    location: 'duffey',
    name: 'Duffey'
  },
  {
    id: 3,
    country: 'canada',
    location: 'duffey',
    name: 'Duffey'
  },
  {
    id: 4,
    country: 'canada',
    location: 'duffey',
    name: 'Duffey'
  },
  {
    id: 5,
    country: 'canada',
    location: 'duffey',
    name: 'Duffey'
  },

]