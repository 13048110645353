import React, { Component } from 'react';
import '../styles/styles.css';
import 'tachyons';

export default class Info extends Component {
  state = {
  }

  render() {
    return (
      <div>
        <div className='tc'>
          <h1 className='f1'>Info</h1>
        </div>
      </div>
    )
  }
}