export const photos = [
  {
    id: 1,
    country: 'cuba',
    location: 'veradero',
    name: 'veradero'
  },
  {
    id: 2,
    country: 'cuba',
    location: 'veradero',
    name: 'veradero'
  },
  {
    id: 3,
    country: 'cuba',
    location: 'veradero',
    name: 'veradero'
  },
  {
    id: 4,
    country: 'cuba',
    location: 'veradero',
    name: 'veradero'
  },
  {
    id: 5,
    country: 'cuba',
    location: 'veradero',
    name: 'veradero'
  },
  {
    id: 6,
    country: 'cuba',
    location: 'veradero',
    name: 'veradero'
  },

]