export const locations = [
  {
    id: 1,
    country: 'england',
    name: 'Buxton',
    thumb: 'buxton'
  },
  {
    id: 2,
    country: 'england',
    name: 'Ilkley',
    thumb: 'ilkley'
  },
  {
    id: 3,
    country: 'england',
    name: 'Lympne',
    thumb: 'lympne'
  },
  {
    id: 4,
    country: 'england',
    name: 'Margate',
    thumb: 'margate'
  },
  {
    id: 5,
    country: 'england',
    name: 'Salisbury',
    thumb: 'salisbury'
  },
  {
    id: 6,
    country: 'england',
    name: 'York',
    thumb: 'york'
  },
  
]