export const locations = [
  {
    id: 1,
    country: 'antigua-and-barbuda',
    name: 'Antigua Beaches',
    thumb: 'antigua-beaches'
  },
  {
    id: 1,
    country: 'antigua-and-barbuda',
    name: 'Antigua Historic',
    thumb: 'antigua-historic'
  },
  {
    id: 1,
    country: 'antigua-and-barbuda',
    name: 'Antigua Accom.',
    thumb: 'antigua-accommodation'
  },
  {
    id: 2,
    country: 'antigua-and-barbuda',
    name: 'Barbuda',
    thumb: 'barbuda'
  }
]