export const locations = [
  {
    id: 1,
    country: 'spain',
    name: 'Alicante',
    thumb: 'alicante'
  },
  {
    id: 2,
    country: 'spain',
    name: 'Javea',
    thumb: 'javea'
  },
  {
    id: 3,
    country: 'spain',
    name: 'Valencia',
    thumb: 'valencia'
  }

]