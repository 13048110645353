export const locations = [
  {
    id: 1,
    country: 'cuba',
    name: 'Havana',
    thumb: 'havana'
  },
  {
    id: 2,
    country: 'cuba',
    name: 'Veradero',
    thumb: 'veradero'
  },
  
]