export const locations = [
  {
    id: 1,
    country: 'ireland',
    name: 'Dublin',
    thumb: 'dublin'
  },
  {
    id: 2,
    country: 'ireland',
    name: 'Galway',
    thumb: 'galway'
  }

]