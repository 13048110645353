export const photos = [
  {
    id: 1,
    country: 'france',
    location: 'bethune',
    name: 'Bethune'
  },
  {
    id: 2,
    country: 'france',
    location: 'bethune',
    name: 'Bethune'
  },
  {
    id: 3,
    country: 'france',
    location: 'bethune',
    name: 'Bethune'
  },
  {
    id: 4,
    country: 'france',
    location: 'bethune',
    name: 'Bethune'
  },
  {
    id: 5,
    country: 'france',
    location: 'bethune',
    name: 'Bethune'
  },
  {
    id: 6,
    country: 'france',
    location: 'bethune',
    name: 'Bethune'
  },
  {
    id: 7,
    country: 'france',
    location: 'bethune',
    name: 'Bethune'
  },
  {
    id: 8,
    country: 'france',
    location: 'bethune',
    name: 'Bethune'
  },
  {
    id: 9,
    country: 'france',
    location: 'bethune',
    name: 'Bethune'
  }

]