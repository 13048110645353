export const locations = [
  {
    id: 1,
    country: 'usa',
    name: 'Cape Cod',
    thumb: 'cape-cod'
  },
  {
    id: 2,
    country: 'usa',
    name: 'Miami',
    thumb: 'miami'
  },
  {
    id: 3,
    country: 'usa',
    name: 'New York',
    thumb: 'new-york'
  },
  {
    id: 4,
    country: 'usa',
    name: 'Providence',
    thumb: 'providence'
  },
  {
    id: 5,
    country: 'usa',
    name: 'Washington D.C',
    thumb: 'washington-dc'
  }

]