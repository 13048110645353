export const locations = [
  {
    id: 1,
    country: 'brazil',
    name: 'Iguazu Falls',
    thumb: 'iguazu-falls'
  },
  {
    id: 2,
    country: 'brazil',
    name: 'Pantanal',
    thumb: 'pantanal'
  },
  {
    id: 3,
    country: 'brazil',
    name: 'Rio de Janeiro',
    thumb: 'rio-de-janeiro'
  }
]