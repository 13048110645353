export const locations = [
  {
    id: 1,
    country: 'colombia',
    name: 'Bogota',
    thumb: 'bogota'
  },
  {
    id: 2,
    country: 'colombia',
    name: 'Cartagena',
    thumb: 'cartagena'
  },
  {
    id: 3,
    country: 'colombia',
    name: 'Medellin',
    thumb: 'medellin'
  },
  {
    id: 4,
    country: 'colombia',
    name: 'Palomino',
    thumb: 'palomino'
  },
  {
    id: 5,
    country: 'colombia',
    name: 'Quindio',
    thumb: 'quindio'
  }

]