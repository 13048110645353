export const photos = [
  {
    id: 1,
    country: 'italy',
    location: 'turin',
    name: 'Turin'
  },
  {
    id: 2,
    country: 'italy',
    location: 'turin',
    name: 'Turin'
  },
  {
    id: 3,
    country: 'italy',
    location: 'turin',
    name: 'Turin'
  },
  {
    id: 4,
    country: 'italy',
    location: 'turin',
    name: 'Turin'
  },
  {
    id: 5,
    country: 'italy',
    location: 'turin',
    name: 'Turin'
  },
  {
    id: 6,
    country: 'italy',
    location: 'turin',
    name: 'Turin'
  },
  {
    id: 7,
    country: 'italy',
    location: 'turin',
    name: 'Turin'
  },
  {
    id: 8,
    country: 'italy',
    location: 'turin',
    name: 'Turin'
  },
  {
    id: 9,
    country: 'italy',
    location: 'turin',
    name: 'Turin'
  },
  {
    id: 10,
    country: 'italy',
    location: 'turin',
    name: 'Turin'
  }

]