export const photos = [
  {
    id: 1,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 2,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 3,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 4,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 5,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 6,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 7,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 8,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 9,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 10,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 11,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 12,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 13,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 14,
    country: 'canada',
    location: 'drumheller',
    name: 'Royal Tyrrell Museum'
  },
  {
    id: 15,
    country: 'canada',
    location: 'drumheller',
    name: 'drumhellerheller Badlands'
  },
  {
    id: 16,
    country: 'canada',
    location: 'drumheller',
    name: 'drumhellerheller Badlands'
  },

]