export const photos = [
  {
    id: 1,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 2,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 3,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 4,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 5,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 6,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 7,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 8,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 9,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 10,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 11,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 12,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 13,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 14,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 15,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 16,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 17,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 18,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 19,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 20,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 21,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 22,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 23,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 24,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 25,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 26,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 27,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 28,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 29,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 30,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 31,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 32,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 33,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 34,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 35,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 36,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 37,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  },
  {
    id: 38,
    country: 'scotland',
    location: 'edinburgh',
    name: 'Edinburgh'
  }

]